import { Backdrop, CircularProgress } from "@mui/material";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux/es/exports";
import { Outlet, Navigate, useRoutes } from "react-router-dom";

import DashboardLayout from "src/layouts/dashboard";
import ForgotPasswordPage from "src/pages/forgot-password";
import ForgotPasswordOtpPage from "src/pages/forgot-password-otp";
import { Unauthorized } from "src/unauthorized-page";

export const IndexPage = lazy(() => import("src/pages/app"));
export const BlogPage = lazy(() => import("src/pages/blog"));
export const UserPage = lazy(() => import("src/pages/user"));
export const LoginPage = lazy(() => import("src/pages/login"));
export const ProductsPage = lazy(() => import("src/pages/products"));
export const Page404 = lazy(() => import("src/pages/page-not-found"));

export const UserAccountSettingsPage = lazy(() =>
  import("src/pages/account/user/settings")
);
export const BusinessAccountSettingsPage = lazy(() =>
  import("src/pages/account/business/settings")
);
export const BusinessAccountBranchFormPage = lazy(() =>
  import("src/pages/account/business/branch/form")
);

export const BusinessPage = lazy(() => import("src/pages/business-management"));
export const BusinessFormPage = lazy(() =>
  import("src/pages/business-management/form")
);

export const BusinessBranchPage = lazy(() =>
  import("src/pages/business-management/branch")
);
export const BusinessBranchFormPage = lazy(() =>
  import("src/pages/business-management/branch/form")
);

export const BusinessSalesAgentsPage = lazy(() =>
  import("src/pages/business-management/sales-agents")
);
export const BusinessSalesAgentFormPage = lazy(() =>
  import("src/pages/business-management/sales-agents/form")
);

export const BusinessPackagePage = lazy(() =>
  import("src/pages/business-management/package")
);

export const BusinessAddonPage = lazy(() =>
  import("src/pages/business-management/addon")
);

export const BusinessDetails = lazy(() =>
  import("src/pages/business-management/details/details")
);

export const CustomerPage = lazy(() => import("src/pages/customer-management"));
export const CustomerFormPage = lazy(() =>
  import("src/pages/customer-management/form")
);
export const CustomerDetailsPage = lazy(() =>
  import("src/pages/customer-management/details/details")
);

export const SalesAgentPage = lazy(() => import("src/pages/sales-agent"));
export const SalesAgentFormPage = lazy(() =>
  import("src/pages/sales-agent/form")
);

export const BrandPage = lazy(() => import("src/pages/vehicle/brand"));
export const BrandFormPage = lazy(() => import("src/pages/vehicle/brand/form"));

export const HorsePowerPage = lazy(() =>
  import("src/pages/vehicle/horse-power")
);
export const HorsePowerFormPage = lazy(() =>
  import("src/pages/vehicle/horse-power/form")
);

export const EngineCapacityPage = lazy(() =>
  import("src/pages/vehicle/engine-capacity")
);
export const EngineCapacityFormPage = lazy(() =>
  import("src/pages/vehicle/engine-capacity/form")
);

export const NoOfCylinderPage = lazy(() =>
  import("src/pages/vehicle/no-of-cylinder")
);
export const NoOfCylinderFormPage = lazy(() =>
  import("src/pages/vehicle/no-of-cylinder/form")
);

export const WarrantyPage = lazy(() => import("src/pages/vehicle/warranty"));
export const WarrantyFormPage = lazy(() =>
  import("src/pages/vehicle/warranty/form")
);

export const ZiwoListFormPage = lazy(() =>
  import("src/pages/ziwo-list/form")
);

export const ServiceContractPage = lazy(() =>
  import("src/pages/vehicle/service-contract")
);
export const ServiceContractFormPage = lazy(() =>
  import("src/pages/vehicle/service-contract/form")
);

export const IsSingleOwnerPage = lazy(() =>
  import("src/pages/vehicle/is-single-owner")
);
export const IsSingleOwnerFormPage = lazy(() =>
  import("src/pages/vehicle/is-single-owner/form")
);

export const DoorPage = lazy(() => import("src/pages/vehicle/doors"));
export const DoorFormPage = lazy(() => import("src/pages/vehicle/doors/form"));

export const SeatPage = lazy(() => import("src/pages/vehicle/seats"));
export const SeatFormPage = lazy(() => import("src/pages/vehicle/seats/form"));

export const BrandModelPage = lazy(() =>
  import("src/pages/vehicle/brand-model/index")
);
export const BrandModelFormPage = lazy(() =>
  import("src/pages/vehicle/brand-model/form")
);

export const BodyTypePage = lazy(() => import("src/pages/vehicle/body-type"));
export const BodyTypeFormPage = lazy(() =>
  import("src/pages/vehicle/body-type/form")
);

export const TransmissionTypePage = lazy(() =>
  import("src/pages/vehicle/transmission-type")
);
export const TransmissionTypeFormPage = lazy(() =>
  import("src/pages/vehicle/transmission-type/form")
);

export const ReginalSpecPage = lazy(() =>
  import("src/pages/vehicle/regional-specs")
);
export const ReginalSpecFormPage = lazy(() =>
  import("src/pages/vehicle/regional-specs/form")
);

export const FuelTypePage = lazy(() => import("src/pages/vehicle/fuel-type"));
export const FuelTypeFormPage = lazy(() =>
  import("src/pages/vehicle/fuel-type/form")
);

export const AutomarketColorPage = lazy(() =>
  import("src/pages/vehicle/automarket-color")
);
export const AutomarketColorFormPage = lazy(() =>
  import("src/pages/vehicle/automarket-color/form")
);

export const TechnicalFeaturePage = lazy(() =>
  import("src/pages/vehicle/technical-feature")
);
export const TechnicalFeatureFormPage = lazy(() =>
  import("src/pages/vehicle/technical-feature/form")
);

export const BadgePage = lazy(() => import("src/pages/vehicle/badge"));
export const BadgeFormPage = lazy(() => import("src/pages/vehicle/badge/form"));

export const DrivetrainPage = lazy(() =>
  import("src/pages/vehicle/drive-train")
);
export const DrivetrainFormPage = lazy(() =>
  import("src/pages/vehicle/drive-train/form")
);

export const TargetMarketPage = lazy(() =>
  import("src/pages/vehicle/target-market")
);
export const TargetMarketFormPage = lazy(() =>
  import("src/pages/vehicle/target-market/form")
);

export const AdListingPage = lazy(() => import("src/pages/ad-listing"));
export const AdListingFormPage = lazy(() =>
  import("src/pages/ad-listing/form")
);
export const AdListingApproveRejectPage = lazy(() =>
  import("src/pages/ad-listing/approve-reject")
);

export const ReviewPage = lazy(() => import("src/pages/vehicle/review"));
export const ReviewFormPage = lazy(() =>
  import("src/pages/vehicle/review/form")
);

export const SteeringSidePage = lazy(() =>
  import("src/pages/vehicle/steering-side")
);
export const SteeringSideFormPage = lazy(() =>
  import("src/pages/vehicle/steering-side/form")
);

export const CustomerReviewPage = lazy(() =>
  import("src/pages/vehicle/customer-review")
);
export const CustomerReviewFormPage = lazy(() =>
  import("src/pages/vehicle/customer-review/form")
);
export const PageTemplatePage = lazy(() =>
  import("src/pages/settings/page-template")
);
export const PageTemplateFormPage = lazy(() =>
  import("src/pages/settings/page-template/form")
);

export const EmailTemplatePage = lazy(() =>
  import("src/pages/settings/email-template")
);
export const EmailTemplateFormPage = lazy(() =>
  import("src/pages/settings/email-template/form")
);
export const EmailTemplateDetailsPage = lazy(() =>
  import("src/pages/settings/email-template/details")
);

export const NewsPage = lazy(() => import("src/pages/settings/news"));
export const NewsFormPage = lazy(() => import("src/pages/settings/news/form"));

export const GuidesAndFAQPage = lazy(() =>
  import("src/pages/settings/guides-and-faq")
);
export const GuidesAndFAQFormPage = lazy(() =>
  import("src/pages/settings/guides-and-faq/form")
);

export const FAQPage = lazy(() => import("src/pages/settings/faq"));
export const FAQFormPage = lazy(() => import("src/pages/settings/faq/form"));
export const MainSliderPage = lazy(() =>
  import("src/pages/settings/main-slider")
);
export const MainSliderFormPage = lazy(() =>
  import("src/pages/settings/main-slider/form")
);

export const ContactEnquiryPage = lazy(() => import("src/pages/enquiry"));
export const ContactEnquiryDetailPage = lazy(() =>
  import("src/pages/enquiry/details")
);

export const DealerEnquiryPage = lazy(() => import("src/pages/dealer-inquiry"));

export const ZiwoListPage = lazy(() => import("src/pages/ziwo-list"));

export const ZiwoListDetailPage = lazy(() =>
  import("src/pages/ziwo-list/details")
);

export const DealerEnquiryDetailPage = lazy(() =>
  import("src/pages/dealer-inquiry/details")
);

export const VehicleConditionPage = lazy(() =>
  import("src/pages/vehicle/condition")
);
export const VehicleConditionFormPage = lazy(() =>
  import("src/pages/vehicle/condition/form")
);

//packages
export const AddOnPackage = lazy(() => import("src/pages/add-ons/packages"));
export const AddOnPackageFormPage = lazy(() =>
  import("src/pages/add-ons/packages/form")
);

export const AddOnBusiness = lazy(() => import("src/pages/add-ons/business"));
export const AddOnBusinessFormPage = lazy(() =>
  import("src/pages/add-ons/business/form")
);

export const AddOnCustomer = lazy(() => import("src/pages/add-ons/customer"));
export const AddOnCustomerFormPage = lazy(() =>
  import("src/pages/add-ons/customer/form")
);

export const VehicleModelTrim = lazy(() =>
  import("src/pages/vehicle/model-trim")
);
export const VehicleModelTrimFormPage = lazy(() =>
  import("src/pages/vehicle/model-trim/form")
);

export const VehicleReportsPage = lazy(() =>
  import("src/pages/vehicle/reports")
);
export const VehicleReportsDetailPage = lazy(() =>
  import("src/pages/vehicle/reports/details")
);
export const Notifications = lazy(() => import("src/pages/notifications"));

export const ChatPage = lazy(() => import("src/pages/chat"));
export const ConfigurationPage = lazy(() => import("src/pages/configuration"));

export const Advertisement = lazy(() =>
  import("src/pages/advertisement/index")
);
export const AdvertisementFormPage = lazy(() =>
  import("src/pages/advertisement/form")
);

export const AutomarketServicePage = lazy(() =>
  import("src/pages/settings/automarket-service")
);
export const AutomarketServiceFormPage = lazy(() =>
  import("src/pages/settings/automarket-service/form")
);

export const AutomarketFeaturePage = lazy(() =>
  import("src/pages/settings/automarket-feature")
);
export const AutomarketFeatureFormPage = lazy(() =>
  import("src/pages/settings/automarket-feature/form")
);

export const ServiceHistoryPage = lazy(() =>
  import("src/pages/vehicle/service-history/index")
);
export const ServiceHistoryFormPage = lazy(() =>
  import("src/pages/vehicle/service-history/form")
);

export default function Router() {
  const { accessToken } = useSelector((state) => state.auth);
  const routes = useRoutes([
    {
      element: accessToken ? (
        <DashboardLayout>
          <Suspense
            fallback={
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            }
          >
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        // { element: <IndexPage />, index: true },
        { path: "/dashboard", element: <IndexPage />, index: true },
        { path: "/user/", element: <UserPage /> },
        // { path: "/products/", element: <ProductsPage /> },
        // { path: "/blog/", element: <BlogPage /> },
        { path: "/notifications", element: <Notifications /> },
        { path: "/chat", element: <ChatPage /> },
        { path: "/configurations", element: <ConfigurationPage /> },
        {
          path: "/account/user/settings/",
          element: <UserAccountSettingsPage />,
        },
        {
          path: "/account/business/settings/",
          element: <BusinessAccountSettingsPage />,
        },
        {
          path: "/account/business/branch/manage/",
          element: <BusinessAccountBranchFormPage />,
        },
        {
          path: "/account/business/branch/manage/:id/",
          element: <BusinessAccountBranchFormPage />,
        },

        { path: "/business-management/", element: <BusinessPage /> },
        { path: "/business-management/manage/", element: <BusinessFormPage /> },
        {
          path: "/business-management/manage/:id/",
          element: <BusinessFormPage />,
        },

        {
          path: "/business-management/:business_id/branch/",
          element: <BusinessBranchPage />,
        },
        {
          path: "/business-management/:business_id/branch/manage/",
          element: <BusinessBranchFormPage />,
        },
        {
          path: "/business-management/:business_id/branch/manage/:id/",
          element: <BusinessBranchFormPage />,
        },

        {
          path: "/business-management/:business_id/sales-agents/",
          element: <BusinessSalesAgentsPage />,
        },
        {
          path: "/business-management/:business_id/sales-agents/manage/",
          element: <BusinessSalesAgentFormPage />,
        },
        {
          path: "/business-management/:business_id/sales-agents/manage/:id/",
          element: <BusinessSalesAgentFormPage />,
        },

        {
          path: "/business-management/:business_id/packages/",
          element: <BusinessPackagePage />,
        },
        {
          path: "/business-management/:business_id/add-ons/",
          element: <BusinessAddonPage />,
        },
        {
          path: "/business-management/:business_id/details/",
          element: <BusinessDetails />,
        },

        { path: "/customer-management/", element: <CustomerPage /> },
        { path: "/customer-management/manage/", element: <CustomerFormPage /> },
        {
          path: "/customer-management/manage/:id/",
          element: <CustomerFormPage />,
        },
        {
          path: "/customer-management/:id/details/",
          element: <CustomerDetailsPage />,
        },

        { path: "/advertisement/", element: <Advertisement /> },
        { path: "/advertisement/manage/", element: <AdvertisementFormPage /> },
        {
          path: "/advertisement/manage/:id/",
          element: <AdvertisementFormPage />,
        },

        { path: "/sales-agent/", element: <SalesAgentPage /> },
        { path: "/sales-agent/manage/", element: <SalesAgentFormPage /> },
        { path: "/sales-agent/manage/:id/", element: <SalesAgentFormPage /> },

        { path: "/vehicle/brand/", element: <BrandPage /> },
        { path: "/vehicle/brand/manage/", element: <BrandFormPage /> },
        { path: "/vehicle/brand/manage/:id/", element: <BrandFormPage /> },

        { path: "/vehicle/engine-capacity/", element: <EngineCapacityPage /> },
        {
          path: "/vehicle/engine-capacity/manage/",
          element: <EngineCapacityFormPage />,
        },
        {
          path: "/vehicle/engine-capacity/manage/:id/",
          element: <EngineCapacityFormPage />,
        },

        { path: "/vehicle/horsepower/", element: <HorsePowerPage /> },
        {
          path: "/vehicle/horsepower/manage/",
          element: <HorsePowerFormPage />,
        },
        {
          path: "/vehicle/horsepower/manage/:id/",
          element: <HorsePowerFormPage />,
        },

        { path: "/vehicle/no-of-cylinder/", element: <NoOfCylinderPage /> },
        {
          path: "/vehicle/no-of-cylinder/manage/",
          element: <NoOfCylinderFormPage />,
        },
        {
          path: "/vehicle/no-of-cylinder/manage/:id/",
          element: <NoOfCylinderFormPage />,
        },

        { path: "/vehicle/warranty/", element: <WarrantyPage /> },
        {
          path: "/vehicle/warranty/manage/",
          element: <WarrantyFormPage />,
        },
        {
          path: "/vehicle/warranty/manage/:id/",
          element: <WarrantyFormPage />,
        },

        {
          path: "/vehicle/service-contract/manage/:id/",
          element: <ServiceContractFormPage />,
        },

        {
          path: "/vehicle/service-contract/",
          element: <ServiceContractPage />,
        },
        {
          path: "/vehicle/service-contract/manage/",
          element: <ServiceContractFormPage />,
        },

        { path: "/vehicle/single-owner/", element: <IsSingleOwnerPage /> },
        {
          path: "/vehicle/single-owner/manage/",
          element: <IsSingleOwnerFormPage />,
        },
        {
          path: "/vehicle/single-owner/manage/:id/",
          element: <IsSingleOwnerFormPage />,
        },

        {
          path: "/vehicle/doors/manage/:id/",
          element: <DoorFormPage />,
        },
        { path: "/vehicle/doors/", element: <DoorPage /> },
        {
          path: "/vehicle/doors/manage/",
          element: <DoorFormPage />,
        },

        {
          path: "/vehicle/seats/manage/:id/",
          element: <SeatFormPage />,
        },
        { path: "/vehicle/seats/", element: <SeatPage /> },
        {
          path: "/vehicle/seats/manage/",
          element: <SeatFormPage />,
        },

        { path: "/vehicle/brand/", element: <BrandPage /> },
        { path: "/vehicle/brand/manage/", element: <BrandFormPage /> },
        { path: "/vehicle/brand/manage/:id/", element: <BrandFormPage /> },

        { path: "/vehicle/service-history/", element: <ServiceHistoryPage /> },
        {
          path: "/vehicle/service-history/manage/",
          element: <ServiceHistoryFormPage />,
        },
        {
          path: "/vehicle/service-history/manage/:id/",
          element: <ServiceHistoryFormPage />,
        },

        { path: "/vehicle/brand-model/", element: <BrandModelPage /> },
        {
          path: "/vehicle/brand-model/manage/",
          element: <BrandModelFormPage />,
        },
        {
          path: "/vehicle/brand-model/manage/:id/",
          element: <BrandModelFormPage />,
        },
        {
          path: "/vehicle/transmission-type/",
          element: <TransmissionTypePage />,
        },
        {
          path: "/vehicle/transmission-type/manage/",
          element: <TransmissionTypeFormPage />,
        },
        {
          path: "/vehicle/transmission-type/manage/:id/",
          element: <TransmissionTypeFormPage />,
        },

        { path: "/vehicle/regional-specs/", element: <ReginalSpecPage /> },
        {
          path: "/vehicle/regional-specs/manage/",
          element: <ReginalSpecFormPage />,
        },
        {
          path: "/vehicle/regional-specs/manage/:id/",
          element: <ReginalSpecFormPage />,
        },

        { path: "/vehicle/body-type/", element: <BodyTypePage /> },
        { path: "/vehicle/body-type/manage/", element: <BodyTypeFormPage /> },
        {
          path: "/vehicle/body-type/manage/:id/",
          element: <BodyTypeFormPage />,
        },

        { path: "/vehicle/fuel-type/", element: <FuelTypePage /> },
        { path: "/vehicle/fuel-type/manage/", element: <FuelTypeFormPage /> },
        {
          path: "/vehicle/fuel-type/manage/:id/",
          element: <FuelTypeFormPage />,
        },

        { path: "/vehicle/model-trim/", element: <VehicleModelTrim /> },
        {
          path: "/vehicle/model-trim/manage/",
          element: <VehicleModelTrimFormPage />,
        },
        {
          path: "/vehicle/model-trim/manage/:id/",
          element: <VehicleModelTrimFormPage />,
        },

        {
          path: "/vehicle/automarket-color/",
          element: <AutomarketColorPage />,
        },
        {
          path: "/vehicle/automarket-color/manage/",
          element: <AutomarketColorFormPage />,
        },
        {
          path: "/vehicle/automarket-color/manage/:id/",
          element: <AutomarketColorFormPage />,
        },

        {
          path: "/vehicle/technical-feature/",
          element: <TechnicalFeaturePage />,
        },
        {
          path: "/vehicle/technical-feature/manage/",
          element: <TechnicalFeatureFormPage />,
        },
        {
          path: "/vehicle/technical-feature/manage/:id/",
          element: <TechnicalFeatureFormPage />,
        },
        { path: "/vehicle/badge/", element: <BadgePage /> },
        { path: "/vehicle/badge/manage/", element: <BadgeFormPage /> },
        { path: "/vehicle/badge/manage/:id/", element: <BadgeFormPage /> },

        { path: "/vehicle/drivetrain/", element: <DrivetrainPage /> },
        {
          path: "/vehicle/drivetrain/manage/",
          element: <DrivetrainFormPage />,
        },
        {
          path: "/vehicle/drivetrain/manage/:id/",
          element: <DrivetrainFormPage />,
        },
        { path: "/vehicle/target-market/", element: <TargetMarketPage /> },
        {
          path: "/vehicle/target-market/manage/",
          element: <TargetMarketFormPage />,
        },
        {
          path: "/vehicle/target-market/manage/:id/",
          element: <TargetMarketFormPage />,
        },

        { path: "/ad-listing/:type/", element: <AdListingPage /> },
        { path: "/ad-listing/:type/manage/", element: <AdListingFormPage /> },
        {
          path: "/ad-listing/:type/manage/:id/",
          element: <AdListingFormPage />,
        },
        {
          path: "/ad-listing/:type/details/:id/",
          element: <AdListingApproveRejectPage />,
        },

        { path: "/ad-listing/:type/", element: <AdListingPage /> },
        { path: "/ad-listing/:type/manage/", element: <AdListingFormPage /> },
        {
          path: "/ad-listing/:type/manage/:id/",
          element: <AdListingFormPage />,
        },
        {
          path: "/ad-listing/:type/details/:id/",
          element: <AdListingApproveRejectPage />,
        },

        { path: "settings/main-slider/", element: <MainSliderPage /> },
        {
          path: "settings/main-slider/manage/",
          element: <MainSliderFormPage />,
        },
        {
          path: "settings/main-slider/manage/:id/",
          element: <MainSliderFormPage />,
        },

        { path: "vehicle/review/", element: <ReviewPage /> },
        { path: "vehicle/review/manage/", element: <ReviewFormPage /> },
        { path: "vehicle/review/manage/:id/", element: <ReviewFormPage /> },

        { path: "vehicle/steering-side/", element: <SteeringSidePage /> },
        {
          path: "vehicle/steering-side/manage/",
          element: <SteeringSideFormPage />,
        },
        {
          path: "vehicle/steering-side/manage/:id/",
          element: <SteeringSideFormPage />,
        },

        { path: "vehicle/single-owner/", element: <SteeringSidePage /> },
        {
          path: "vehicle/single-owner/manage/",
          element: <SteeringSideFormPage />,
        },
        {
          path: "vehicle/single-owner/manage/:id/",
          element: <SteeringSideFormPage />,
        },

        { path: "vehicle/customer-review/", element: <CustomerReviewPage /> },
        {
          path: "vehicle/customer-review/manage/",
          element: <CustomerReviewFormPage />,
        },
        {
          path: "vehicle/customer-review/manage/:id/",
          element: <CustomerReviewFormPage />,
        },

        { path: "/settings/page-template/", element: <PageTemplatePage /> },
        {
          path: "/settings/page-template/manage/",
          element: <PageTemplateFormPage />,
        },
        {
          path: "/settings/page-template/manage/:id/",
          element: <PageTemplateFormPage />,
        },

        { path: "/settings/email-template/", element: <EmailTemplatePage /> },
        {
          path: "/settings/email-template/edit/:id/",
          element: <EmailTemplateFormPage />,
        },
        // {
        //   path: "/settings/email-template/manage/:id/",
        //   element: <EmailTemplateFormPage />,
        // },
        {
          path: "/settings/email-template/manage/:id/",
          element: <EmailTemplateDetailsPage />,
        },

        { path: "/settings/news/", element: <NewsPage /> },
        {
          path: "/settings/news/manage/",
          element: <NewsFormPage />,
        },
        {
          path: "/settings/news/manage/:id/",
          element: <NewsFormPage />,
        },

        {
          path: "/settings/faq/",
          element: <FAQPage />,
        },
        {
          path: "/settings/faq/manage/",
          element: <FAQFormPage />,
        },
        {
          path: "/settings/faq/manage/:id/",
          element: <FAQFormPage />,
        },

        {
          path: "/settings/guides-and-faq/",
          element: <GuidesAndFAQPage />,
        },
        {
          path: "/settings/guides-and-faq/manage/",
          element: <GuidesAndFAQFormPage />,
        },
        {
          path: "/settings/guides-and-faq/manage/:id/",
          element: <GuidesAndFAQFormPage />,
        },

        {
          path: "/settings/automarket-service/",
          element: <AutomarketServicePage />,
        },
        {
          path: "/settings/automarket-service/manage/",
          element: <AutomarketServiceFormPage />,
        },
        {
          path: "/settings/automarket-service/manage/:id/",
          element: <AutomarketServiceFormPage />,
        },

        {
          path: "/settings/automarket-feature/",
          element: <AutomarketFeaturePage />,
        },
        {
          path: "/settings/automarket-feature/manage/",
          element: <AutomarketFeatureFormPage />,
        },
        {
          path: "/settings/automarket-feature/manage/:id/",
          element: <AutomarketFeatureFormPage />,
        },

        { path: "/contact-enquiry/", element: <ContactEnquiryPage /> },
        {
          path: "/contact-enquiry/details/:id/",
          element: <ContactEnquiryDetailPage />,
        },
        { path: "/dealer-enquiry/", element: <DealerEnquiryPage /> },
        { path: "/ziwo-list/", element: <ZiwoListPage /> },
        {
          path: "/ziwo-list/details/:id/",
          element: <ZiwoListDetailPage />,
        },
        {
          path: "/ziwo-list/manage/",
          element: <ZiwoListFormPage />,
        },
        {
          path: "/ziwo-list/manage/:id/",
          element: <ZiwoListFormPage />,
        },
        {
          path: "/dealer-enquiry/details/:id/",
          element: <DealerEnquiryDetailPage />,
        },

        { path: "/reports/", element: <ContactEnquiryPage /> },
        {
          path: "/contact-enquiry/details/:id/",
          element: <ContactEnquiryDetailPage />,
        },

        { path: "/vehicle/condition/", element: <VehicleConditionPage /> },
        {
          path: "/vehicle/condition/manage/",
          element: <VehicleConditionFormPage />,
        },
        {
          path: "/vehicle/condition/manage/:id/",
          element: <VehicleConditionFormPage />,
        },

        { path: "/vehicle/reports/", element: <VehicleReportsPage /> },
        {
          path: "/vehicle/reports/details/:id/",
          element: <VehicleReportsDetailPage />,
        },

        { path: "/add-ons/packages/", element: <AddOnPackage /> },
        {
          path: "/add-ons/packages/manage/",
          element: <AddOnPackageFormPage />,
        },
        {
          path: "/add-ons/packages/manage/:id/",
          element: <AddOnPackageFormPage />,
        },

        { path: "/add-ons/business/", element: <AddOnBusiness /> },
        {
          path: "/add-ons/business/manage/",
          element: <AddOnBusinessFormPage />,
        },
        {
          path: "/add-ons/business/manage/:id/",
          element: <AddOnBusinessFormPage />,
        },

        { path: "/add-ons/customer/", element: <AddOnCustomer /> },
        {
          path: "/add-ons/customer/manage/",
          element: <AddOnCustomerFormPage />,
        },
        {
          path: "/add-ons/customer/manage/:id/",
          element: <AddOnCustomerFormPage />,
        },
      ],
    },
    { path: "/", element: <Navigate to="/dashboard/" replace /> },
    {
      path: "/login/",
      element: <LoginPage />,
    },
    {
      path: "/forgot-password/",
      element: <ForgotPasswordPage />,
    },
    {
      path: "/forgot-password/:email/otp",
      element: <ForgotPasswordOtpPage />,
    },
    {
      path: "/404/",
      element: <Page404 />,
    },
    {
      path: "/403/",
      element: <Unauthorized />,
    },
    {
      path: "*",
      element: <Page404 />,
      //element: <Navigate to="/404/" replace />,
    },
  ]);

  return routes;
}
